import axios from '@/common/axios'
import qs from 'qs'

export function addAdverseEventAnnual (data) {
  return axios({
    method: 'post',
    url: '/quality/adverseEventAnnual/add',
    data: qs.stringify(data)
  })
}

export function deleteAdverseEventAnnual (id) {
  return axios({
    method: 'delete',
    url: '/quality/adverseEventAnnual/delete/' + id
  })
}

export function updateAdverseEventAnnual (data) {
  return axios({
    method: 'put',
    url: '/quality/adverseEventAnnual/update',
    data: qs.stringify(data)
  })
}

export function selectAdverseEventAnnualInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/adverseEventAnnual/info/' + id
  })
}

export function selectAdverseEventAnnualList (query) {
  return axios({
    method: 'get',
    url: '/quality/adverseEventAnnual/list',
    params: query
  })
}
