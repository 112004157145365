<template>
  <div id="adverseEventAnnual">
    <el-dialog
      :title="adverseEventAnnualFormTitle"
      width="1200px"
      :visible.sync="adverseEventAnnualDialogVisible"
      :close-on-click-modal="false"
      @close="adverseEventAnnualDialogClose"
    >
      <el-form
        ref="adverseEventAnnualFormRef"
        :model="adverseEventAnnualForm"
        :rules="adverseEventAnnualFormRules"
        label-position="right"
        label-width="160px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="报告时间" prop="reportTime">
              <el-date-picker v-model="adverseEventAnnualForm.reportTime" placeholder="请选择报告时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="编码" prop="code">
              <el-input v-model="adverseEventAnnualForm.code" placeholder="请输入编码" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="汇总时间" prop="summaryTime">
              <el-date-picker v-model="adverseEventAnnualForm.summaryTime" placeholder="请选择汇总时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业名称" prop="enterpriseName">
              <el-input v-model="adverseEventAnnualForm.enterpriseName" placeholder="请输入企业名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业地址" prop="enterpriseAddress">
              <el-input v-model="adverseEventAnnualForm.enterpriseAddress" placeholder="请输入企业地址" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人" prop="contact">
              <el-input v-model="adverseEventAnnualForm.contact" placeholder="请输入联系人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="传真" prop="fax">
              <el-input v-model="adverseEventAnnualForm.fax" placeholder="请输入传真" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮编" prop="postcode">
              <el-input v-model="adverseEventAnnualForm.postcode" placeholder="请输入邮编" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话" prop="telephone">
              <el-input v-model="adverseEventAnnualForm.telephone" placeholder="请输入电话" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="e-mail" prop="email">
              <el-input v-model="adverseEventAnnualForm.email" placeholder="请输入e-mail" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="医疗器械信息">
          <vxe-toolbar v-if="adverseEventAnnualFormTitle !== '医疗器械不良事件年度汇总报告表详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="adverseEventAnnualDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="medicalName"
              title="医疗器械名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="productName"
              title="商品名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="category"
              title="类别"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="categoryCode"
              title="分类代号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="registrationNum"
              title="注册证号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column v-if="adverseEventAnnualFormTitle !== '医疗器械不良事件年度汇总报告表详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="医疗器械不良事件" prop="haveOrNot">
              <el-radio-group v-model="adverseEventAnnualForm.haveOrNot">
                <el-radio :label="1">
                  有
                </el-radio>
                <el-radio :label="0">
                  无
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="变更情况" prop="changeSituation">
              <el-input
                v-model="adverseEventAnnualForm.changeSituation"
                placeholder="请输入变更情况（产品注册证书、管理类别、说明书、标准、使用范围等的变更）"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="本企业生产的医疗器械在境内出现医疗器械不良事件的情况汇总分析" prop="summaryAnalysis">
              <el-input
                v-model="adverseEventAnnualForm.summaryAnalysis"
                placeholder="请输入本企业生产的医疗器械在境内出现医疗器械不良事件的情况汇总分析（事件发生情况、报告情况、事件描述、事件最终结果、企业对事件的分析、企业对产品采取的措施、涉及用户的联系资料，可另附A4纸）"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="境外不良事件发生情况" prop="outSituation">
              <el-input
                v-model="adverseEventAnnualForm.outSituation"
                placeholder="请输入境外不良事件发生情况（产品在境外发生不良事件的数量、程度及涉及人群资料等）"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="省级监测技术机构评价意见" prop="provinceComment">
              <el-input
                v-model="adverseEventAnnualForm.provinceComment"
                placeholder="请输入省级监测技术机构评价意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国家监测技术机构评价意见" prop="countryComment">
              <el-input
                v-model="adverseEventAnnualForm.countryComment"
                placeholder="请输入国家监测技术机构评价意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="省级监测技术机构接收日期" prop="provinceReceivedDate">
              <el-date-picker v-model="adverseEventAnnualForm.provinceReceivedDate" placeholder="请选择省级监测技术机构接收日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国家监测技术机构接收日期" prop="countryReceivedDate">
              <el-date-picker v-model="adverseEventAnnualForm.countryReceivedDate" placeholder="请选择国家监测技术机构接收日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告人" prop="reporter">
              <el-input v-model="adverseEventAnnualForm.reporter" placeholder="请输入报告人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产企业" prop="manufacturer">
              <el-input v-model="adverseEventAnnualForm.manufacturer" placeholder="请输入生产企业" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="adverseEventAnnualDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="adverseEventAnnualFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="企业名称">
        <el-input v-model="searchForm.enterpriseName" placeholder="请输入企业名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="adverseEventAnnualPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column label="报告时间">
        <template slot-scope="scope">
          <span v-if="scope.row.reportTime">{{ scope.row.reportTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="编码" />
      <el-table-column label="汇总时间">
        <template slot-scope="scope">
          <span v-if="scope.row.summaryTime">{{ scope.row.summaryTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="enterpriseName" label="企业名称" />
      <el-table-column prop="enterpriseAddress" label="企业地址" />
      <el-table-column prop="contact" label="联系人" />
      <el-table-column prop="fax" label="传真" />
      <el-table-column prop="postcode" label="邮编" />
      <el-table-column prop="telephone" label="电话" />
      <el-table-column prop="email" label="e-mail" />
      <el-table-column prop="changeSituation" label="变更情况" />
      <el-table-column label="医疗器械不良事件">
        <template slot-scope="scope">
          <span v-if="scope.row.haveOrNot === 0">无</span>
          <span v-if="scope.row.haveOrNot === 1">有</span>
        </template>
      </el-table-column>
      <el-table-column prop="summaryAnalysis" label="本企业生产的医疗器械在境内出现医疗器械不良事件的情况汇总分析" />
      <el-table-column prop="outSituation" label="境外不良事件发生情况" />
      <el-table-column prop="provinceComment" label="省级监测技术机构评价意见" />
      <el-table-column prop="countryComment" label="国家监测技术机构评价意见" />
      <el-table-column prop="reporter" label="报告人" />
      <el-table-column label="省级监测技术机构接收日期">
        <template slot-scope="scope">
          <span v-if="scope.row.provinceReceivedDate">{{ scope.row.provinceReceivedDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="国家监测技术机构接收日期">
        <template slot-scope="scope">
          <span v-if="scope.row.countryReceivedDate">{{ scope.row.countryReceivedDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="manufacturer" label="生产企业" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="adverseEventAnnualPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addAdverseEventAnnual, deleteAdverseEventAnnual, updateAdverseEventAnnual, selectAdverseEventAnnualInfo, selectAdverseEventAnnualList } from '@/api/quality/adverseEventAnnual'

export default {
  data () {
    return {
      adverseEventAnnualDialogVisible: false,
      adverseEventAnnualFormTitle: '',
      adverseEventAnnualForm: {
        id: '',
        reportTime: '',
        code: '',
        summaryTime: '',
        enterpriseName: '',
        enterpriseAddress: '',
        contact: '',
        fax: '',
        postcode: '',
        telephone: '',
        email: '',
        changeSituation: '',
        haveOrNot: '',
        summaryAnalysis: '',
        outSituation: '',
        provinceComment: '',
        countryComment: '',
        reporter: '',
        provinceReceivedDate: '',
        countryReceivedDate: '',
        manufacturer: '',
        adverseEventAnnualDetailJson: ''
      },
      adverseEventAnnualFormRules: {
        enterpriseName: [{ required: true, message: '企业名称不能为空', trigger: ['blur', 'change']}]
      },
      adverseEventAnnualPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        enterpriseName: ''
      },
      adverseEventAnnualDetailList: []
    }
  },
  created () {
    selectAdverseEventAnnualList(this.searchForm).then(res => {
      this.adverseEventAnnualPage = res
    })
  },
  methods: {
    adverseEventAnnualDialogClose () {
      this.$refs.adverseEventAnnualFormRef.resetFields()
      this.adverseEventAnnualDetailList = []
    },
    adverseEventAnnualFormSubmit () {
      if (this.adverseEventAnnualFormTitle === '医疗器械不良事件年度汇总报告表详情') {
        this.adverseEventAnnualDialogVisible = false
        return
      }
      this.$refs.adverseEventAnnualFormRef.validate(async valid => {
        if (valid) {
          this.adverseEventAnnualForm.adverseEventAnnualDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.adverseEventAnnualFormTitle === '新增医疗器械不良事件年度汇总报告表') {
            await addAdverseEventAnnual(this.adverseEventAnnualForm)
          } else if (this.adverseEventAnnualFormTitle === '修改医疗器械不良事件年度汇总报告表') {
            await updateAdverseEventAnnual(this.adverseEventAnnualForm)
          }
          this.adverseEventAnnualPage = await selectAdverseEventAnnualList(this.searchForm)
          this.adverseEventAnnualDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.adverseEventAnnualFormTitle = '新增医疗器械不良事件年度汇总报告表'
      this.adverseEventAnnualDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAdverseEventAnnual(row.id)
        if (this.adverseEventAnnualPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.adverseEventAnnualPage = await selectAdverseEventAnnualList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.adverseEventAnnualFormTitle = '修改医疗器械不良事件年度汇总报告表'
      this.adverseEventAnnualDialogVisible = true
      this.selectAdverseEventAnnualInfoById(row.id)
    },
    handleInfo (index, row) {
      this.adverseEventAnnualFormTitle = '医疗器械不良事件年度汇总报告表详情'
      this.adverseEventAnnualDialogVisible = true
      this.selectAdverseEventAnnualInfoById(row.id)
    },
    selectAdverseEventAnnualInfoById (id) {
      selectAdverseEventAnnualInfo(id).then(res => {
        this.adverseEventAnnualForm.id = res.id
        this.adverseEventAnnualForm.reportTime = res.reportTime
        this.adverseEventAnnualForm.code = res.code
        this.adverseEventAnnualForm.summaryTime = res.summaryTime
        this.adverseEventAnnualForm.enterpriseName = res.enterpriseName
        this.adverseEventAnnualForm.enterpriseAddress = res.enterpriseAddress
        this.adverseEventAnnualForm.contact = res.contact
        this.adverseEventAnnualForm.fax = res.fax
        this.adverseEventAnnualForm.postcode = res.postcode
        this.adverseEventAnnualForm.telephone = res.telephone
        this.adverseEventAnnualForm.email = res.email
        this.adverseEventAnnualForm.changeSituation = res.changeSituation
        this.adverseEventAnnualForm.haveOrNot = res.haveOrNot
        this.adverseEventAnnualForm.summaryAnalysis = res.summaryAnalysis
        this.adverseEventAnnualForm.outSituation = res.outSituation
        this.adverseEventAnnualForm.provinceComment = res.provinceComment
        this.adverseEventAnnualForm.countryComment = res.countryComment
        this.adverseEventAnnualForm.reporter = res.reporter
        this.adverseEventAnnualForm.provinceReceivedDate = res.provinceReceivedDate
        this.adverseEventAnnualForm.countryReceivedDate = res.countryReceivedDate
        this.adverseEventAnnualForm.manufacturer = res.manufacturer
        this.adverseEventAnnualDetailList = res.adverseEventAnnualDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAdverseEventAnnualList(this.searchForm).then(res => {
        this.adverseEventAnnualPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAdverseEventAnnualList(this.searchForm).then(res => {
        this.adverseEventAnnualPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAdverseEventAnnualList(this.searchForm).then(res => {
        this.adverseEventAnnualPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
